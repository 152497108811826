import { JSONObject } from '@segment/analytics-next';
import { PayPalCheckout, client, paypalCheckout } from 'braintree-web';
import { Dispatch, SetStateAction } from 'react';

import { calmLogger } from '@/utils/calmLogger';

import { PayPalToken } from '../PayPalButton/types';

export const getPaypalCheckoutInstance = async (clientToken: string, paymentFormCurrency: string) => {
	try {
		const clientInstance = await client.create({
			authorization: clientToken,
		});

		const paypalCheckoutInstance = await paypalCheckout.create({
			client: clientInstance,
		});

		await paypalCheckoutInstance.loadPayPalSDK({
			vault: true,
			intent: 'tokenize',
			currency: paymentFormCurrency,
		});

		return paypalCheckoutInstance;
	} catch (err) {
		calmLogger.error('Error creating paypal checkout instance', {}, err);
	}
};

const getButtonHeight = (): number => {
	if (!document) return 55;

	const { clientWidth: width } = document.body;

	if (width < 475) return 52;

	return 55;
};

export const createPayPalButton = async ({
	clientToken,
	setSelectedPaymentType,
	paymentFormCurrency,
	amount,
	onApprove,
	onCancel,
	onError,
}: {
	clientToken: string;
	setSelectedPaymentType: Dispatch<SetStateAction<string | null>>;
	paymentFormCurrency: string;
	amount: number;
	onApprove: (data: PayPalToken, paypalCheckoutInstance: PayPalCheckout) => void;
	onCancel: (err: { orderId: string }) => void;
	onError: (errorMessageToDisplay: string, eventProps?: JSONObject, errorCode?: string) => void;
}) => {
	const paypalCheckoutInstance = await getPaypalCheckoutInstance(clientToken, paymentFormCurrency);

	if (!window.paypal || !paypalCheckoutInstance) return;

	window.paypal
		.Buttons({
			fundingSource: 'paypal', // window.paypal.FUNDING.PAYPAL,
			style: {
				height: getButtonHeight(),
				size: 'responsive',
				shape: 'pill',
			},
			createBillingAgreement: () => {
				setSelectedPaymentType('paypal');
				return paypalCheckoutInstance.createPayment({
					flow: 'vault' as paypal.FlowType,
					billingAgreementDescription: 'Calm Premium',
					intent: 'tokenize' as paypal.Intent,
					currency: paymentFormCurrency,
					amount,
					enableShippingAddress: true,
					shippingAddressEditable: true,
				});
			},
			onApprove: async data => onApprove(data, paypalCheckoutInstance),
			onCancel,
			onError: err => onError(err, { payment_type: 'paypal' }),
		})
		.render(`#paypal-button`);

	return paypalCheckoutInstance;
};
