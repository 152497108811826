import styled, { css } from 'styled-components';

import { IconButton } from '@calm-web/design-system';

const withBorderCss = css`
	flex: 0 0 48px;
	height: 48px;
	padding: 12px;
	width: 48px;
`;

export const Wrapper = styled(IconButton).attrs({
	color: 'white',
})<{
	$withBorder?: boolean;
	$isFaved: boolean;
}>`
	flex: 0 0 24px;
	height: 24px;
	width: 24px;
	min-width: 24px;
	min-height: 24px;

	& > div,
	svg {
		width: 24px;
		height: 24px;
	}

	${p =>
		p.$withBorder &&
		css`
			background: ${p.$isFaved ? '#FF5959' : 'rgba(255, 255, 255, 0.2)'};
			border-radius: 50%;
			${withBorderCss}
		`}
`;
