import { ParsedUrlQuery } from 'querystring';

import { PartnerDetails } from '@/components/dtcPartnerships/shared/types';
import Purchase from '@/hooks/purchase/purchase';
import { Device } from '@/store/device/types';
import { Prices } from '@/store/prices/types';
import { PurchaseParams, RouteSpecificPurchaseParams } from '@/store/purchaseParams/types';
import { User } from '@/store/user/types';
import getParamAsString from '@/utils/getParamsAsString';

import isValidLifetimeCoupon from '../prices/isValidLifetimeCoupon';

const validPlans = ['monthly', 'yearly', 'lifetime'] as const;
export type ValidPlan = (typeof validPlans)[number];
export const isValidPlan = (value: string): value is ValidPlan => validPlans.includes(value as ValidPlan);

function getPlan(plan: string | null, partner?: PartnerDetails | null, prices?: Prices | null): ValidPlan {
	if (partner?.offer_details?.billing_plan) {
		return partner.offer_details.billing_plan.includes('1m') ? 'monthly' : 'yearly';
	}
	if (isValidLifetimeCoupon(prices)) {
		return 'lifetime';
	}
	if (plan && isValidPlan(plan)) {
		return plan;
	}
	return 'yearly';
}

const initCouponValue = ({
	plan,
	query,
	prices,
}: {
	plan: ValidPlan;
	query: ParsedUrlQuery;
	prices: Prices | null;
}): string | null => {
	if (prices?.coupons[plan || 'yearly']) {
		return prices?.coupons[plan || 'yearly']?.id || null;
	}

	return getParamAsString(query.coupon) || null;
};

/*
  GRO-4576: 14/30 day free Trial experiment
*/
const extendedTrialMap: Record<string, string> = {
	'c284698d-5cab-44e1-a2b6-7d04687feff9': '14_day_free_trial',
	'efe5a293-862e-477e-a87a-2cd77b6aa681': '30_day_free_trial',
};

const getPromotion = ({
	promotion,
	utm_medium,
	tl,
}: {
	promotion?: string;
	utm_medium?: string;
	tl?: string;
}) => {
	if (utm_medium === 'paid' && tl) return extendedTrialMap[tl] || null;
	return getParamAsString(promotion);
};

/*
  END GRO-4576
*/

export function initPurchaseParams({
	query,
	prices,
	user,
	device,
	routeSpecificPurchaseParams,
	partner,
}: {
	query: ParsedUrlQuery;
	prices: Prices | null;
	user: User | null;
	device: Device | null;
	routeSpecificPurchaseParams?: RouteSpecificPurchaseParams;
	partner?: PartnerDetails | null;
}): PurchaseParams {
	const plan = getPlan(getParamAsString(query.plan), partner, prices);
	const customTrialLength = Number(getParamAsString(query.trialLength));
	const trialLengthInt = Number.isNaN(customTrialLength) ? null : customTrialLength;

	const initialPurchaseParams: PurchaseParams = {
		plan,
		coupon: routeSpecificPurchaseParams?.coupon ?? initCouponValue({ query, prices, plan }),
		promotion: routeSpecificPurchaseParams?.promotion ?? getPromotion(query),
		samsungGUID: null,
		guestPass: getParamAsString(query.guestPass),
		calmjwt: getParamAsString(query.calmjwt),
		billingCountryCode: device?.ip_country,
		is30DayOverride:
			routeSpecificPurchaseParams?.is30DayOverride ?? Boolean(getParamAsString(query.is30DayOverride)),
		...routeSpecificPurchaseParams,
		trialLengthOverride: trialLengthInt, // Only to be used for initialization, use `purchaseType.duration` for logic
	};

	return {
		purchaseType: prices
			? new Purchase({ ...initialPurchaseParams, abTests: {}, prices, user }).purchaseType
			: null,
		...initialPurchaseParams,
	};
}
