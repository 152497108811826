import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { IconButton, minWidth, units } from '@calm-web/design-system';

import helpFilled from 'icons/help-filled.svg';

import CreditCardForm from '../CreditCardForm';

export const Wrapper = styled.div`
	max-width: 750px;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
`;

export const NoticeWrapper = styled.div`
	margin-top: 16px;
`;

export const SectionHeader = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	max-height: 25px;
	gap: ${units(1)};
`;

export const Link = styled.a`
	width: 100%;
	border-radius: ${theme('buttons.borderRadius')};
	justify-content: center;
	display: flex;
	color: ${palette('white')};
	background: ${palette('gradientCalmBrand')};
	padding: 8px;
	transition: all ${theme('animation.transition')};

	&:hover {
		background: ${palette('gradientMountainLake')};
	}
`;

export const PurchaseFormSectionWrapper = styled.div`
	background: white;
	margin-bottom: 20px;
	border-radius: 20px;
	width: 100%;
	padding: 28px 20px;

	> p {
		margin-bottom: 20px;
	}

	${minWidth('desktop')} {
		padding: 48px;
	}
`;

export const HelpIcon = styled(helpFilled)`
	height: 24px;
	width: 24px;
	&&& path {
		fill: ${palette('gray6')};
	}
`;

export const HelpButton = styled(IconButton).attrs({
	tabIndex: 0,
})`
	cursor: pointer;
	padding: 0;
	height: 24px;
`;

export const StyledCreditCardForm = styled(CreditCardForm)`
	margin-top: 20px;
`;

export const SecureTransactionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 21px;
`;

export const CTAContainer = styled.div`
	max-width: 560px;
	margin: auto;
	padding-bottom: 32px;

	${minWidth('tablet')} {
		padding-bottom: 54px;
	}
`;
