import styled from 'styled-components';

import { minWidth } from '@calm-web/design-system';

import { BannerConfigType } from './data';

export const StyledBanner = styled.a<{
	bannerConfig: BannerConfigType;
}>`
	background-color: ${({ bannerConfig }) => bannerConfig.backgroundColor};
	color: ${({ bannerConfig }) => bannerConfig.textColor};
	display: flex;
	padding: 8px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	height: 40px;
`;

export const BannerMessaging = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BannerSpacer = styled.span`
	margin: 0 8px;
	display: none;

	${minWidth('mobileLarge')} {
		display: block;
	}
`;

export const BannerArrow = styled.span`
	margin-left: 8px;
	display: flex;
	align-items: center;
`;

export const CloseWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0;
}`;

export const CTAText = styled.span`
	display: none;

	${minWidth('mobileLarge')} {
		display: block;
	}
`;
