import { MessageDescriptor, defineMessages } from 'react-intl';

import { isWithinDateRange } from '.';

export type BannerConfigType = {
	longCopy: MessageDescriptor;
	url: string;
	ctaText: MessageDescriptor;
	textColor: string;
	backgroundColor: string;
	onClose: () => void;
	startDate: string;
	endDate: string;
	visiblePaths: string[];
	coupon?: string;
	queries?: { key: string; value: string; path: string }[];
	utm_source?: string;
};

export const messages = defineMessages({
	copyWMHD: {
		id: 'offer.banner.copyWMHD',
		defaultMessage: 'Get 50% off for World Mental Health Day',
		description: 'Description of offer',
	},
	copyCalmiversary: {
		id: 'offer.banner.copyCalmiversary',
		defaultMessage: "It's our birthday! Get 50% off",
		description: 'Description of offer',
	},
	ctaText: {
		id: 'offer.banner.CTA',
		defaultMessage: 'Get Offer Now',
		description: 'Description of offer',
	},
});

export const configBase = {
	ctaText: messages.ctaText,
	textColor: '#fff',
	backgroundColor: '#1C2056',
	url: '/new-annual-offer',
	onClose: () => {},
};

export const UA_WMHD = {
	...configBase,
	longCopy: messages.copyCalmiversary,
	coupon: 'wmhd2024_UA_annual50_Google',
	startDate: '10/09/2024',
	endDate: '10/16/2024',
	visiblePaths: ['/ua-homepage', '/signup-flow'],
	queries: [
		{
			path: '/signup-flow',
			key: 'focus',
			value: 'sleep_questionnaire',
		},
		{
			path: '/signup-flow',
			key: 'step',
			value: 'landing',
		},
	],
};

export const WMHDOrganic = {
	...configBase,
	longCopy: messages.copyWMHD,
	utm_source: 'home_banner',
	startDate: '10/09/2024',
	endDate: '10/13/2024',
	url: '/wmhd-offer',
	visiblePaths: ['/'],
};

// This is a test config for unit tests - Do not remove
// TODO: Move this to mock test file
export const UNIT_TEST_CONFIG = {
	ctaText: messages.ctaText,
	textColor: '#fff',
	backgroundColor: '#1C2056',
	url: '/new-annual-offer',
	onClose: () => {},
	longCopy: messages.copyCalmiversary,
	coupon: 'wmhd2024_UA_annual50_Google',
	startDate: '10/01/2020',
	endDate: '10/16/2124',
	visiblePaths: ['/test-flow'],
};

export const bannerConfigs = [UA_WMHD, WMHDOrganic, UNIT_TEST_CONFIG];

export function getActiveConfig(pathname: string): BannerConfigType {
	const filtered = bannerConfigs.filter(({ startDate, endDate, visiblePaths }) => {
		return isWithinDateRange(startDate, endDate) && visiblePaths.includes(pathname);
	});
	return filtered[0] || null;
}
