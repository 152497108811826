import { app, clipboard } from '@microsoft/teams-js';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Modal, Text, FontSizes, FontWeights, Loader } from '@calm-web/design-system';

import { ShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState } from '@/hooks/store';
import { Guide } from '@/store/guide/types';
import { useExecuteIsInMsTeamsMeeting } from '@/teams-calm/src/live-share-hooks/useIsInMsTeamsMeeting';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';

import { messages } from './messages';
import { BreakWordWrapper, CopyShareLinkField, ShareTitleComponent } from './styles';

interface ShareModalProps {
	setIsModalOpen: Dispatch<SetStateAction<boolean>>;
	isModalOpen: boolean;
	currentGuide: Guide | null;
}

export interface AlertNotice {
	isSuccess: boolean;
	message: string;
}

const ShareModal = ({ isModalOpen, currentGuide, setIsModalOpen }: ShareModalProps) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { isMobile } = useDeviceState();

	const { shareUrl, senderShareToken: shareToken } = useContext(ShareContext);
	const shareLinkText: string = formatMessage(messages.shareLinkText);

	const { isTeamsApp } = useGetIsTeamsApp();
	const { isInMeeting, isLoading } = useExecuteIsInMsTeamsMeeting();

	const onClick = async () => {
		logEvent({
			eventName: 'Share Link : Copied',
			eventProps: {
				share_token: shareToken,
			},
		});
		if (isTeamsApp && shareUrl) {
			const blob = new Blob([shareUrl], { type: 'text/plain' });
			if (clipboard.isSupported() && !isInMeeting) {
				await clipboard.write(blob);
			}
		}
	};

	const onClose = () => {
		logEvent({
			eventName: 'Screen : Exited',
			eventProps: {
				screen: 'Modal',
				type: 'New Feature Share',
			},
		});
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (isMobile && typeof navigator.share === 'function' && shareUrl && isModalOpen) {
			setIsModalOpen(false);
			navigator
				.share({
					url: shareUrl,
					text: shareLinkText,
				})
				.catch(() => {});
		}
	}, [isModalOpen, setIsModalOpen, shareUrl, shareLinkText, isMobile]);

	useEffect(() => {
		if (isModalOpen) {
			logEvent({
				eventName: 'Screen : Viewed',
				eventProps: {
					screen: 'Modal',
					type: 'New Feature Share',
				},
			});
		}
	}, [isModalOpen, logEvent]);

	function renderCopyLinkField() {
		let copyLinkField;

		if (isTeamsApp && isLoading) {
			return <Loader />;
		}

		// microsoft clipboard api is not supported in a meeting stage context
		if (!isTeamsApp || (app.isInitialized() && clipboard.isSupported() && !isInMeeting)) {
			copyLinkField = (
				<CopyShareLinkField
					colorTheme="light"
					url={shareUrl || ''}
					onClick={onClick}
					notificationBackgroundColor="noticeBgSuccess"
					successMessage={formatMessage(messages.linkCopyConfirm)}
					label={formatMessage(messages.copy)}
				>
					<Text
						noMargin
						color="gray5"
						el="p"
						size={FontSizes.base}
						weight={FontWeights.Regular}
						id="shareUrlDisplay"
					>
						{shareUrl}{' '}
					</Text>
				</CopyShareLinkField>
			);
		} else {
			copyLinkField = (
				<Text
					noMargin
					color="gray5"
					el="p"
					size={FontSizes.base}
					weight={FontWeights.Regular}
					id="shareUrlDisplay"
					word-wrap="break-word"
				>
					<BreakWordWrapper>{shareUrl} </BreakWordWrapper>
				</Text>
			);
		}

		return copyLinkField;
	}

	return (
		<Modal
			isOpen={isModalOpen}
			closeModal={onClose}
			canClose
			hasOverflowY={false}
			aria-label="messages.modalTitle"
		>
			<>
				<ShareTitleComponent>{formatMessage(messages.share)}</ShareTitleComponent>
				<Text align="center" noMargin color="gray5" el="p" size={FontSizes.base} weight={FontWeights.Regular}>
					{formatMessage(messages.shareModalMessage)}
				</Text>

				{renderCopyLinkField()}
			</>
		</Modal>
	);
};

export default ShareModal;
