import { Experiment, ExperimentClient, ExperimentUser } from '@amplitude/experiment-js-client';

import { Device } from '@/store/device/types';
import { User } from '@/store/user/types';

export const AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE = 'client-unavailable';

export type AmplitudeExperimentClient = ExperimentClient | typeof AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE;

export const initAmplitudeExperimentClient = async (): Promise<AmplitudeExperimentClient> => {
	if (!process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENTS_DEPLOYMENT_KEY) {
		throw Error('Missing amplitude experiments deployment key');
	}

	try {
		const experimentClient = Experiment.initializeWithAmplitudeAnalytics(
			process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENTS_DEPLOYMENT_KEY,
		);
		await experimentClient.start();

		return experimentClient;
	} catch (err) {
		return AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE;
	}
};

export const setupAmplitudeExperimentUser = (user: User | null, device: Device): ExperimentUser => {
	return {
		device_id: device.deviceId,
		user_id: user?.id,
		platform: 'web',
		language: device.language,
		user_properties: {
			...(user?.created_at && { user_account_created: new Date(user.created_at).toISOString() }),
			...(user?.subscription && {
				subscription_in_free_trial_window: user.subscription.in_free_trial_window,
			}),
			...(user?.subscription && { subscription_is_valid: user.subscription.valid }),
			...(user?.subscription && { subscription_type: user.subscription.type }),
		},
	};
};
