import { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import LimitedTimeCallout from '@/components/PreSignupFlow/Components/LimitedTimeCallout';
import paymentMessages from '@/components/SimplifiedSignup/Payment/messages';
import { useFormattedCurrency } from '@/hooks/purchase';
import {
	useAuthFormModeState,
	usePartnerState,
	usePricesState,
	usePurchaseParamsState,
	useUserState,
} from '@/hooks/store';
import { useIsMobile } from '@/hooks/ui/useIsMobile';
import useQueryParams from '@/hooks/utils/useQueryParams';

import messages from '../../Account/messages';
import { Card } from '../../Account/styles';
import { AccountScreenConfig } from '../../Account/types';
import { useSimplifiedSignupContext } from '../../Context';
import AuthIneligible from '../AuthIneligible';
import AuthForm from '../ConfigurableAuthForm';
import OfferHasEnded from '../OfferHasEnded';

const SisuAuthForm: React.FC = () => {
	const { name, activeScreenConfig } = useSimplifiedSignupContext();
	const {
		authHeaderSize,
		heading,
		limitedTimeOffer,
		terms,
		loginTerms,
		signupTerms,
		hideSocialSignup = false,
		hideAuthModeToggle = false,
		emailSignupCTA,
		subheading,
		isWithinModal,
	} = activeScreenConfig as AccountScreenConfig;

	const [isMobile] = useIsMobile();
	const { formatMessage, formatDate } = useIntl();
	const formatCurrency = useFormattedCurrency();
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const authFormMode = useAuthFormModeState();
	const partner = usePartnerState();
	const user = useUserState();
	const query = useQueryParams();

	const buildHeading = () => {
		if ((isMobile && isWithinModal) || !heading) {
			return null;
		}

		const isSimplifiedFTUE: boolean = query?.utm_content?.includes('_ft-sf') || false;

		if (isSimplifiedFTUE && authFormMode === 'signup') {
			return formatMessage(messages.signupFormSimpleHeading);
		}

		const { message, placeholders = {} } = heading({ authFormMode, prices, purchaseParams });

		return formatMessage(message, placeholders);
	};

	const formatTerms = (terms: MessageDescriptor | undefined) => {
		if (!terms) return '';
		return formatMessage(terms, {
			date: partner?.contract_expires_at
				? formatDate(new Date(partner?.contract_expires_at ?? ''), { dateStyle: 'medium' })
				: '',
			originalPrice: formatCurrency(
				prices.original[partner?.offer_details?.d2c_subscription_plan || 'yearly'],
			),
			cancel: (...chunks: ReactNode[]) => (
				<a
					href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
					target="_blank"
					rel="noreferrer"
				>
					{chunks}
				</a>
			),
			a: (...chunks: ReactNode[]) => (
				<a href="/terms" target="_blank">
					{chunks}
				</a>
			),
		});
	};

	const isIneligiblePartnerUser = user?.subscription?.valid && partner;
	if (isIneligiblePartnerUser) {
		return <AuthIneligible />;
	}

	return (
		<div>
			{limitedTimeOffer && (
				<LimitedTimeCallout>{formatMessage(paymentMessages.limitedTimeCallout)}</LimitedTimeCallout>
			)}
			{buildHeading() && (
				<Text
					align="center"
					noMargin
					el="h1"
					weight={FontWeights.Medium}
					size={authHeaderSize || FontSizes['2xl']}
				>
					{buildHeading()}
				</Text>
			)}
			<AuthForm
				loginTermsText={loginTerms && formatTerms(loginTerms)}
				signupTermsText={signupTerms && formatTerms(signupTerms)}
				partnerTerms={terms as string}
				showMarketingOptIn={false}
				customEventProps={{ source: name }}
				hideFacebookSignup={hideSocialSignup}
				hideGoogleSignup={hideSocialSignup}
				hideAppleSignup={hideSocialSignup}
				hideAuthModeToggle={hideAuthModeToggle}
				emailSignupCTA={emailSignupCTA}
				subheading={subheading && formatMessage(subheading)}
			/>
		</div>
	);
};

const SisiAuthFormWithWrapper = () => {
	const { activeScreenConfig } = useSimplifiedSignupContext();
	const [isMobile] = useIsMobile();
	const { isWithinModal } = activeScreenConfig as AccountScreenConfig;
	const partner = usePartnerState();

	if (isMobile && isWithinModal) {
		return <SisuAuthForm />;
	}

	if (partner && !partner.offer_details?.valid) {
		return <OfferHasEnded />;
	}
	return (
		<Card isWithinModal={isWithinModal}>
			<SisuAuthForm />
		</Card>
	);
};

export default SisiAuthFormWithWrapper;
