import { MessageDescriptor } from 'react-intl';

import { LegacyCalmError } from '@/hooks/api/types';
import messages from '@/messages/errorMessages';

const genericDeclineCodes = [
	'generic_decline',
	'do_not_honor',
	'invalid_account',
	'pickup_card',
	'service_not_allowed',
	'lost_card',
	'stolen_card',
	'invalid_amount',
] as const;
type GenericDeclineCode = (typeof genericDeclineCodes)[number];
const isGenericDeclineCode = (value: string): value is GenericDeclineCode =>
	genericDeclineCodes.includes(value as GenericDeclineCode);

export const getPurchaseError = (error: LegacyCalmError): MessageDescriptor => {
	const err = {
		...error,
		calmCode: error.calmCode || error.data?.error?.code,
		info: error.info || error.data?.error?.info,
		status: error.status,
	};
	if (err.didTimeout) {
		return messages.timeoutError;
	}

	if (err.calmCode === 'ineligible') {
		return messages.ineligibleCard;
	}

	// Samsung-Specific error message on prommotions
	if (err.calmCode === 'promotion_already_redeemed') {
		return messages.samsungPromotionError;
	}

	if (err.calmCode === 'card_declined') {
		const declineCode = err.info && err.info.decline_code;
		if (declineCode && typeof declineCode === 'string' && isGenericDeclineCode(declineCode)) {
			return messages.contactIssuer;
		}

		if (declineCode === 'insufficient_funds') {
			return messages.insufficientFunds;
		}

		return messages.declinedError;
	}

	if (err.calmCode === 'already_subscribed') {
		return messages.already_subscribed;
	}

	if (err.calmCode === 'invalid_email') {
		return messages.email_invalid;
	}

	if (err.calmCode === 'already_trialed') {
		return messages.already_trialed;
	}

	return messages.declinedError;
};
