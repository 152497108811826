import Image from '@/components/image/NextImage';
import { formatResizedUrl } from '@/utils/app/formatResizedUrl';

import { BlackOverlay, Wrapper } from './styles';
import { ProgramBackgroundProps } from './types';

const ProgramBackground = ({ backgroundImage, alt }: ProgramBackgroundProps) => {
	const widthToHeightRatio =
		backgroundImage?.width && backgroundImage?.height ? backgroundImage.width / backgroundImage.height : 1;

	return (
		<Wrapper>
			<Image
				layout="fill"
				objectFit="cover"
				src={formatResizedUrl(backgroundImage.url, 1920, 1920 * widthToHeightRatio)}
				alt={`${alt} Background Image`}
			/>
			<BlackOverlay />
		</Wrapper>
	);
};

export default ProgramBackground;
