import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { FocusRingVariants, FontSizes, FontWeights, Modal, Text } from '@calm-web/design-system';

import Loader from '@/components/Loader';
import PaymentDetailsModal from '@/components/PreSignupFlow/Components/PaymentDetailsModal';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { PaymentScreenConfig } from '@/components/SimplifiedSignup/Payment/types';
import PaymentError from '@/components/purchase/PaymentError';
import { SubscribePurchaseError, subscribePurchaseErrors } from '@/components/purchase/utils/Purchase';
import { useAnalytics } from '@/hooks/analytics';
import { ProductInfo } from '@/hooks/analytics/types';
import { useGetPurchaseTerms } from '@/hooks/dtcLeadGenPartnership/useGetPurchaseTerms';
import { useFormattedPriceWithTax } from '@/hooks/purchase/useFormattedPriceWithTax';
import {
	useAppLoadedState,
	useDeviceState,
	usePartnerState,
	usePricesState,
	usePurchaseParamsState,
	useUserState,
} from '@/hooks/store';
import isUSPricing from '@/utils/prices/isUSPricing';
import { userCanTrial } from '@/utils/subscriptions';

import PaymentElementsForm from '../PaymentElementsForm';
import PlanSkuToggle from '../PlanSkuToggle';
import PurchaseDetails from '../PurchaseDetails/refreshedPurchaseDetails';
import purchaseMessages from '../messages';
import messages from './messages';
import {
	CTAContainer,
	HelpButton,
	HelpIcon,
	PurchaseFormSectionWrapper,
	Wrapper,
	SectionHeader,
} from './styles';

const PaymentElementsFormRefresh: React.FC<{
	endpoint: string;
	purchaseArgs?: Record<string, unknown>;
	successArgs?: Record<string, unknown>;
	ignoreSubscribeErrors?: boolean;
	subscribePurchaseErrors?: SubscribePurchaseError[];
	analyticsPrefix?: string;
	purchaseTerms?: string;
	ctaCopy?: string;
	productInfo?: ProductInfo;
	afterPurchaseSuccess?: () => void;
	onPurchaseSuccess?: (
		response: Record<string, unknown>,
		purchaseArgs: Record<string, unknown>,
	) => Promise<void>;
	hidePaypal?: boolean;
	terms?: MessageDescriptor;
	children?: ReactNode;
}> = ({
	ctaCopy,
	analyticsPrefix = 'Subscribe : Purchase : Form',
	children,
	ignoreSubscribeErrors = false,
	hidePaypal = false,
	terms,
	endpoint,
	onPurchaseSuccess: onPurchaseSuccessOverride,
	afterPurchaseSuccess,
	productInfo: productInfoOverride,
	purchaseArgs = {},
	successArgs = {},
	subscribePurchaseErrors: subscribePurchaseErrorsOverride,
}) => {
	const purchaseParams = usePurchaseParamsState();
	const { purchaseType } = purchaseParams;
	const appLoaded = useAppLoadedState();
	const user = useUserState();
	const device = useDeviceState();
	const prices = usePricesState();
	const partner = usePartnerState();
	const partnerPurchaseTerms = useGetPurchaseTerms(partner?.offer_details);
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { query } = useRouter();
	const isOrganicTraffic = query?.utm_medium !== 'paid';

	const [isModalOpen, setIsModalOpen] = useState(false);

	const isFreeTrialInitial = userCanTrial(user) && purchaseType?.type === 'freetrial';
	const [isFreeTrial, setIsFreeTrial] = useState(isFreeTrialInitial);
	const { activeScreenConfig, hasFreeTrial = true } = useSimplifiedSignupContext();
	const {
		terms: configTerms,
		showToggle = true,
		trialTerms = messages.freeTrialTerms,
		noTrialTerms = messages.noTrialTerms,
		offerTerms,
		paymentFormHeader,
		paymentFormSubHeader,
	} = activeScreenConfig as PaymentScreenConfig;

	const [noSubscribeError] = useState(
		!ignoreSubscribeErrors && purchaseParams?.purchaseType?.type
			? (subscribePurchaseErrorsOverride ?? subscribePurchaseErrors).includes(
					purchaseParams.purchaseType.type as SubscribePurchaseError,
			  )
			: false,
	);
	const isUSPrice = isUSPricing(prices, device);
	const planPriceWithTax = useFormattedPriceWithTax(prices.current[purchaseParams?.plan || 'yearly']);
	const originalPlanPriceWithTax = useFormattedPriceWithTax(
		prices.original[purchaseParams?.plan || 'yearly'],
	);

	function onTooltipClick(): void {
		setIsModalOpen(true);
		logEvent({
			eventName: 'Subscribe : Purchase : Form : Tool Tip : Clicked',
			eventProps: {
				is_stripe_next: true,
				tool_tip: 'why now',
			},
		});
	}

	function getTermsText(): MessageDescriptor {
		return prices.current[purchaseParams?.plan || 'yearly'] !==
			prices.original[purchaseParams?.plan || 'yearly'] && offerTerms
			? offerTerms
			: noTrialTerms;
	}

	useEffect(() => {
		function evalIsFreeTrial(): boolean {
			if (!hasFreeTrial) return false;
			if ((userCanTrial(user) && purchaseParams.plan === 'yearly') || purchaseType?.isFreeTrial) return true;
			return false;
		}
		setIsFreeTrial(evalIsFreeTrial());
	}, [purchaseParams.plan, purchaseType?.isFreeTrial, user, hasFreeTrial]);

	if (!appLoaded) return <Loader />;

	if (noSubscribeError) {
		return <PaymentError errorType={purchaseParams?.purchaseType?.type as SubscribePurchaseError} />;
	}

	return (
		<Wrapper>
			<PurchaseFormSectionWrapper>
				{isUSPrice && showToggle && isOrganicTraffic ? (
					<PlanSkuToggle terms={terms} />
				) : (
					<>
						<Text el="p" size={FontSizes.lg} weight={FontWeights.Medium}>
							{formatMessage(paymentFormHeader ?? messages.confirmPlanNoToggle, {
								trialLengthInDays: purchaseParams?.purchaseType?.duration || 14,
							})}
						</Text>
						<Text el="p" size={FontSizes.base}>
							{formatMessage(paymentFormSubHeader ?? messages.confirmSubtitle)}
						</Text>
						<PurchaseDetails />
						{configTerms && (
							<Text size={FontSizes.sm} color="blackAlpha60" el="p">
								{formatMessage(configTerms, {
									price: planPriceWithTax,
									cancelAnytimeLink: (...chunks: ReactNode[]) => (
										<a
											href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
											target="_blank"
											rel="noreferrer"
										>
											{chunks}
										</a>
									),
									originalPrice: originalPlanPriceWithTax,
								})}
							</Text>
						)}
					</>
				)}
			</PurchaseFormSectionWrapper>
			<PurchaseFormSectionWrapper>
				<SectionHeader>
					<Text size={FontSizes.lg} weight={FontWeights.Medium}>
						{formatMessage(messages.paymentMethodHeader)}
					</Text>
					{isFreeTrial && (
						<HelpButton
							aria-label={formatMessage(purchaseMessages.cvvHelpModalTitle)}
							onPress={onTooltipClick}
							Icon={HelpIcon}
							focusRingVariant={FocusRingVariants.Dark}
						/>
					)}
				</SectionHeader>
				<PaymentElementsForm
					analyticsPrefix={analyticsPrefix}
					endpoint={endpoint}
					ctaCopy={ctaCopy}
					hidePaypal={hidePaypal}
					onPurchaseSuccess={onPurchaseSuccessOverride}
					afterPurchaseSuccess={afterPurchaseSuccess}
					productInfo={productInfoOverride}
					purchaseArgs={purchaseArgs}
					successArgs={successArgs}
					subscribePurchaseErrors={subscribePurchaseErrorsOverride}
					ignoreSubscribeErrors={ignoreSubscribeErrors}
					hideTerms
				/>
			</PurchaseFormSectionWrapper>
			{children}
			<CTAContainer>
				<Text size={FontSizes.sm} color="blackAlpha60" el="p">
					{partner ? (
						<>
							{partnerPurchaseTerms}
							<br />
							<br />
							{partner?.offer_details?.terms || ''}
						</>
					) : isFreeTrial ? (
						<>
							{formatMessage(trialTerms, {
								price: planPriceWithTax,
								originalPrice: originalPlanPriceWithTax,
								cadence: formatMessage(messages[purchaseParams?.plan || 'yearly']),
								cancelAnytimeLink: (...chunks: ReactNode[]) => (
									<a
										href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
										target="_blank"
										rel="noreferrer"
									>
										{chunks}
									</a>
								),
								a: (...chunks: ReactNode[]) => (
									<a href="/terms" target="_blank">
										{chunks}
									</a>
								),
							})}
						</>
					) : (
						formatMessage(getTermsText(), {
							price: planPriceWithTax,
							cancelAnytimeLink: (...chunks: ReactNode[]) => (
								<a
									href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
									target="_blank"
									rel="noreferrer"
								>
									{chunks}
								</a>
							),
							terms: (...chunks: ReactNode[]) => (
								<a href="/terms" target="_blank">
									{chunks}
								</a>
							),
							cadence: formatMessage(messages[purchaseParams?.plan || 'yearly']),
							originalPrice: originalPlanPriceWithTax,
						})
					)}
				</Text>
			</CTAContainer>
			<Modal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} canClose aria-label={''}>
				<PaymentDetailsModal onCloseClick={(): void => setIsModalOpen(false)} />
			</Modal>
		</Wrapper>
	);
};

export default PaymentElementsFormRefresh;
