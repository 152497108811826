import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import FaveHeart from '@/components/app/contentPlayer/FaveHeart';
import PlayerUpsell from '@/components/app/contentPlayer/PlayerUpsell';
import ShareButton from '@/components/app/contentPlayer/ShareButton';
import Transport from '@/components/app/contentPlayer/Transport';
import { usePacksContext } from '@/components/app/layout/PacksProvider';
import { useShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useThemeState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { formatResizedUrl } from '@/utils/app/formatResizedUrl';

import { useMediaPlayerContext } from '../MediaPlayerProvider';
import ShareModal from '../ShareModal';
import messages from './messages';
import {
	Background,
	Container,
	GuideCopy,
	GuideInfo,
	GuideThumbnail,
	GuideThumbnailContainer,
	GuideTitle,
	PlayerClickTrap,
	PlayerIcons,
	ProgramTitle,
	SmallFaveHeartContainer,
	TransportContainer,
	Wrapper,
} from './styles';

const AudioPlayer = () => {
	const theme = useThemeState();
	const routerPush = useRouterPush();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const { isPreviewMode, playerMode, contentType, hideControls } = useMediaPlayerContext();
	const { currentGuide, currentProgram } = usePacksContext();
	const audioPlayerRef = useRef<HTMLDivElement>(null);
	const { isShareContentRecipient, isFetchingShare } = useShareContext();

	const [playerTheme, setPlayerTheme] = useState<'large' | 'small'>(
		theme.webAppLayout === 'player' ? 'large' : 'small',
	);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const allowPlayerClick = currentGuide && theme.webAppLayout !== 'player';
	const onPlayerClick = async () => {
		if (!allowPlayerClick) return;

		logEvent({ eventName: 'Player Bar : Clicked' });

		await routerPush(`/app/player/${currentGuide.id}`);
	};

	const isVisible = playerMode !== 'stopped' && !isFetchingShare;

	const isLargePlayer = theme.webAppLayout === 'player';
	const isTimer = currentProgram?.meditation_type === 'timer';

	const playerThumbnailUrl = currentProgram?.background_image?.url ?? currentGuide?.cell_image?.url ?? null;

	const getProgramTitle = () => {
		if (currentProgram?.title.toLowerCase() !== currentGuide?.title.toLowerCase()) {
			return currentProgram?.title;
		}

		return currentGuide?.narrator_name;
	};

	// Focus screen reader into audio player when content starts
	// Needs 300ms timeout since that is length of Wrapper animation
	useEffect(() => {
		if (playerMode === 'playing' && contentType === 'audio') {
			const timer = setTimeout(() => {
				if (audioPlayerRef.current) {
					// This focuses the PlayerClickTrap element
					audioPlayerRef.current.querySelector('button')?.focus();
				}
			}, 300);

			return () => clearTimeout(timer);
		}
	}, [audioPlayerRef, playerMode, contentType, currentGuide?.id]);

	useEffect(() => {
		setPlayerTheme(theme.webAppLayout === 'player' ? 'large' : 'small');
	}, [theme.webAppLayout]);

	const showPlayerIcons = !isPreviewMode && currentGuide && !isShareContentRecipient;

	if (isTimer) {
		return null;
	}

	return (
		<Wrapper isVisible={isVisible} $isLargePlayer={isLargePlayer} ref={audioPlayerRef}>
			{playerTheme === 'small' && <Background />}
			<Container $isLargePlayer={isLargePlayer} $isPreviewMode={isPreviewMode} $isZoomApp={theme.isZoomApp}>
				{playerTheme === 'small' && (
					<GuideInfo isVisible={Boolean(currentGuide?.title)}>
						{playerThumbnailUrl && (
							<GuideThumbnailContainer>
								<GuideThumbnail
									src={formatResizedUrl(playerThumbnailUrl, 128, 128)}
									layout="responsive"
									height={128}
									width={128}
									alt=""
								/>
							</GuideThumbnailContainer>
						)}
						<GuideCopy>
							<GuideTitle>{currentGuide?.title}</GuideTitle>
							<ProgramTitle>{getProgramTitle()}</ProgramTitle>
						</GuideCopy>
					</GuideInfo>
				)}
				{allowPlayerClick ? (
					<PlayerClickTrap onPress={onPlayerClick} aria-label={formatMessage(messages.expandPlayerLabel)} />
				) : null}
				<TransportContainer sizeTheme={playerTheme} $isZoomApp={theme.isZoomApp}>
					{showPlayerIcons && playerTheme === 'large' && (
						<PlayerIcons>
							{!hideControls && (
								<FaveHeart withBorder guideId={currentGuide.id} guideTitle={currentGuide.title} />
							)}
							{!currentGuide?.capabilities?.non_shareable && (
								<ShareButton isShareModalVisible={setIsModalOpen} />
							)}
						</PlayerIcons>
					)}
					<PlayerUpsell program={currentProgram}>
						<Transport playerTheme={playerTheme} />
					</PlayerUpsell>
					<ShareModal currentGuide={currentGuide} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
				</TransportContainer>
				{playerTheme === 'small' && (
					<SmallFaveHeartContainer>
						{showPlayerIcons && !hideControls && (
							<FaveHeart withBorder guideId={currentGuide.id} guideTitle={currentGuide.title} />
						)}
					</SmallFaveHeartContainer>
				)}
			</Container>
		</Wrapper>
	);
};

export default AudioPlayer;
