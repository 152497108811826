import styled from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { CloseButton, FocusRingVariants, minWidth } from '@calm-web/design-system';

import GridWrapper from '@/components/ui/GridWrapper';

export const Wrapper = styled.header<{
	isDark: boolean;
	isStatic?: boolean;
	isBannerVisible?: boolean;
	isDisplayBlock?: boolean;
}>`
	color: ${ifProp('isDark', palette('white'), palette('black'))};
	display: ${ifProp('isDisplayBlock', 'block', 'flex')};
	fill: ${ifProp('isDark', palette('white'), palette('black'))};
	height: 80px;
	left: 0;
	position: ${ifProp('isStatic', 'static', 'fixed')};
	top: ${ifProp('isBannerVisible', '40px', '0')};
	width: 100%;
	z-index: 100;

	${minWidth('desktop')} {
		height: 120px;
	}

	&:after {
		background: ${palette('white')};
		bottom: 0;
		content: '';
		left: 0;
		height: 80px;
		opacity: ${ifProp('isDark', '0', '1')};
		position: absolute;
		right: 0;
		top: 0;
		transform: ${ifProp('isDark', 'translateY(-100%)', 'translateY(0)')};
		transition: ${theme('animation.transition')};
		z-index: -1;

		${minWidth('desktop')} {
			height: 120px;
		}
	}
`;

export const GradientBg = styled.div`
	position: absolute;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 3.3e-5) 97.76%);
	opacity: ${ifProp('isDark', '1', '0')};
	top: 0;
	left: 0;
	right: 0;
	bottom: -42.5%;
	z-index: -1;
	transition: ${theme('animation.transition')};
`;

export const Navigation = styled.div`
	align-items: center;
	display: flex;
	font-size: 18px;
	justify-content: space-between;
	width: 100%;

	a {
		color: inherit;
		transition: ${theme('animation.transition')};
	}
	li {
		list-style: none;
	}
	z-index: 100;
`;

export const Logo = styled.img`
	margin-right: 8px;
	width: 72px;

	${minWidth('desktop')} {
		width: 100px;
	}
`;

export const ExitIconWrapper = styled.div`
	align-items: center;
	display: flex;
	margin-left: auto;
`;

export const ExitIcon = styled(CloseButton).attrs<
	{ $isDark?: boolean },
	Pick<Parameters<typeof CloseButton>[0], 'color' | 'focusRingVariant'>
>(props => ({
	color: props.$isDark ? 'white' : 'gray6',
	focusRingVariant: props.$isDark ? FocusRingVariants.Light : FocusRingVariants.Dark,
}))<{
	$isDark?: boolean;
}>`
	padding: 0;
`;

export const HeaderGridWrapper = styled(GridWrapper)``;
