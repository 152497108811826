import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, palette } from 'styled-tools';

import { maxWidth, minWidth } from '@calm-web/design-system';

import HeaderRaw from '@/components/header/Header';

export const SimplifiedSignupFlowContainer = styled.div<{ isWithinModal?: boolean }>`
	background: ${palette('gray0')};
	${ifNotProp(
		'isWithinModal',
		css`
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			overflow-y: scroll;
		`,
	)}
`;

export const SpotifyFlowWrapper = styled.div`
	margin: auto;
`;

export const Header = styled(HeaderRaw)`
	background: ${palette('white')};
	height: 80px;
`;

export const TwoColumnWrapper = styled.div<{ isWithinModal?: boolean }>`
	display: flex;
	flex-direction: ${ifProp('isWithinModal', 'column-reverse', 'column')};
	align-items: center;
	padding: ${ifProp('isWithinModal', '0px', '20px')};

	${minWidth('desktop')} {
		flex-direction: row-reverse;
		justify-content: space-evenly;
		align-items: flex-start;
		max-width: 1440px;
		margin: auto;
		gap: 80px;
		min-height: inherit;
		padding: 0 20px;
	}
`;

export const PageWrap = styled.div<{ isWithinModal?: boolean }>`
	${ifNotProp(
		'isWithinModal',
		css`
			padding-top: 40px;
			min-height: calc(100vh - 40px);
		`,
	)}

	${maxWidth('tablet')} {
		padding-top: 0;

		> div {
			padding: 20px;
		}
	}
`;
