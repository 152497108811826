import styled, { css, keyframes } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { Text, minWidth, units, FontWeights, PaletteColor, maxWidth } from '@calm-web/design-system';

import Header from '@/components/header/Header';
import { HeaderGridWrapper } from '@/components/header/Header/styles';
import GridWrapper from '@/components/ui/GridWrapper';

export const PreSignupFlowBackgroundWrapper = styled.div`
	height: 100%;
	background: ${palette('gray0')};
	overflow: scroll;
`;

export const ProgressBarContainer = styled.div`
	width: 100%;
	background: ${palette('gray0')};
`;

export const fadeIn = (startingPoint: number, percentComplete: number) => keyframes`
  from {
    width: ${startingPoint}%;
  } to {
    width: ${percentComplete}%;
  }
`;

export const ProgressBar = styled.div<{ percentComplete?: number; startingPoint: number }>`
	background: ${palette('accessibleBrandGradient')};
	width: ${p => p.percentComplete ?? 0}%;
	height: 6px;
	animation: ${props => fadeIn(props.startingPoint ?? 0, props.percentComplete ?? 0)} 0.75s ease-in;
`;

export const PreSignupFlowHeader = styled(Header)<{
	isForHandoff: boolean;
}>`
	background: white;
	height: 100px;
	${p =>
		p.isForHandoff &&
		css`
			position: absolute;

			&:after {
				background: transparent;
			}

			svg {
				& path {
					fill: ${palette('black')};
				}
			}

			${minWidth('desktop')} {
				svg {
					& path {
						fill: ${palette('white')};
					}
				}
			}
		`}
	${maxWidth('mobileLarge')} {
		height: 64px;
	}

	${HeaderGridWrapper} {
		max-width: none;
	}
`;

export const PreSignupFlowGridWrapper = styled(GridWrapper)<{
	hideMargin?: boolean;
}>`
	margin: ${ifProp('hideMargin', '0', '0 16px')};

	${minWidth('desktop')} {
		margin: ${ifProp('hideMargin', '0', '0 16px')};
	}
`;

export const Column = styled.div<{
	hasPadding: boolean;
	$ignoreColumnWidth?: boolean;
}>`
	display: flex;
	flex-direction: column;
	grid-column-start: 1;
	grid-column-end: 5;
	min-height: calc(100vh - 100px);
	padding-top: ${ifProp('hasPadding', '44px', '0')};
	position: relative;
	text-align: left;

	${minWidth('desktop')} {
		${p =>
			!p.$ignoreColumnWidth &&
			css`
				grid-column-start: 2;
				grid-column-end: 4;
			`};
	}

	${minWidth('desktop')} {
		margin: 30px 0;
		padding-top: ${ifProp('hasPadding', '42px', '0')};
	}
`;

export const Title = styled(Text).attrs({
	weight: FontWeights.Regular,
	styleAs: 'h4',
	el: 'h1',
})`
	margin-top: 0;
	margin-bottom: ${units(3.5)};
`;

export const GridlessScreen = styled.div<{
	scrollable?: boolean;
	headerPresent?: boolean;
}>`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: ${ifProp('scrollable', 'scroll', 'hidden')};

	${maxWidth('tablet')} {
		max-height: ${ifProp('headerPresent', 'calc(100vh - 64px)', '100vh')};
		top: ${ifProp('headerPresent', '64px', '0')};
	}
`;

export const Container = styled.div<{
	bgColor?: PaletteColor;
}>`
	${p =>
		p.bgColor &&
		css`
			background-color: ${palette(p.bgColor)};
		`}
`;
