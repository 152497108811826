import styled from 'styled-components';

import { IconButton, minWidth } from '@calm-web/design-system';

import infoIcon from 'icons/info-outline.svg';

export const ShareIcon = styled(IconButton)`
	background: rgba(255, 255, 255, 0.2);
	height: 24px;
	width: 24px;
`;

export const TooltipContent = styled.div`
	align-items: center;
	display: flex;
	padding: 0 12px;
	text-align: left;
	width: 288px;

	${minWidth('tablet')} {
		width: 560px;
	}
`;

export const InfoIcon = styled(infoIcon)`
	flex: 0 0 20px;
	height: 20px;
	margin-right: 10px;
	width: 20px;
`;

export const CloseButton = styled(IconButton)`
	margin-left: auto;
	height: 20px;
	width: 20px;

	& svg {
		height: 20px;
		width: 20px;
	}
`;
