import { defineMessages } from 'react-intl';

export default defineMessages({
	isFavedAnnouncement: {
		id: 'faveHeart.isFavedAnnouncement',
		defaultMessage: '{title} added to favorites',
		description: 'Notification to screen reader that a user has favorited a piece of content',
	},
	isUnfavedAnnouncement: {
		id: 'faveHeart.isUnavedAnnouncement',
		defaultMessage: '{title} removed from favorites',
		description: 'Notification to screen reader that a user has unfavorited a piece of content',
	},
});
