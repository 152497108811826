import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useSWR from 'swr';

import apiRequest from '@/utils/apiRequest';

interface Fave {
	guide_id?: string;
	program_id?: string;
	faved_at?: Date;
	unfaved_at?: Date;
}

const useIsFaved = (guideId: string | undefined): [boolean, Dispatch<SetStateAction<boolean>>] => {
	const [isFaved, setIsFaved] = useState(false);

	const { data: faves } = useSWR<Fave[]>('faves', async (endpoint: string) => {
		const res = await apiRequest<{ faves: Fave[] }>({
			endpoint,
			method: 'GET',
			noCache: true,
		});
		return res.data.faves;
	});

	useEffect(() => {
		if (!guideId) return;

		const isGuideFaved = faves
			? faves.filter(fave => fave.guide_id === guideId).some(fave => 'faved_at' in fave)
			: false;

		setIsFaved(isGuideFaved);
	}, [faves, guideId]);

	return [isFaved, setIsFaved];
};

export default useIsFaved;
