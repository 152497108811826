/* eslint-disable global-require */

/* eslint-disable @typescript-eslint/no-var-requires */

import { PayPalCheckout } from 'braintree-web';
import { useEffect, useState } from 'react';

import { useApi } from '@/hooks/api';
import { usePaymentFormValues } from '@/hooks/purchase/usePaymentFormValues';
import { usePurchaseParamsState, usePricesState } from '@/hooks/store';
import { PlanCadence } from '@/store/purchaseParams/types';

import { UNSUPPORTED_PAYPAL_CURRENCIES } from './data';
import { ButtonWrapper, LoadingButton, PayPalButtonWrapper, Wrapper } from './styles';
import { PayPalButtonProps } from './types';

const PayPalButton = ({
	onSubmit,
	onClick,
	onCancel,
	onError,
	children,
	onSuccess,
	requestInfo,
	isDisabled,
	isLoading,
	setIsPayPalAvailable,
	isMonthlyPricingTest,
}: PayPalButtonProps) => {
	const apiRequest = useApi();
	const { paymentFormCurrency } = usePaymentFormValues();

	const { plan } = usePurchaseParamsState();
	const prices = usePricesState();

	const [isVisible, setIsVisible] = useState(true);

	const shouldShowPayPalCheckout = (): boolean => {
		if (!isVisible) {
			return false;
		}

		if (UNSUPPORTED_PAYPAL_CURRENCIES.includes(paymentFormCurrency)) {
			return false;
		}

		return true;
	};

	const processPayPalRequest = async (token: paypal.AuthorizationResponse, _plan: PlanCadence) => {
		const data = await onSubmit({
			requestInfo,
			token,
			paymentType: 'paypal',
			plan: _plan,
		});
		if (data?.error) {
			onError(data.error);
		} else {
			await onSuccess(data.success, 'paypal');
		}
	};

	const getClientToken = async () => {
		const { data } = await apiRequest({
			method: 'GET',
			endpoint: 'subscription/generate-client-token',
		});
		return data.clientToken;
	};

	const onPaymentAuthorized = async (payPalToken: paypal.AuthorizationResponse, plan: PlanCadence) => {
		try {
			await processPayPalRequest(payPalToken, plan);
		} catch (err) {
			onError(err);
		}
	};

	const initBraintree = async () => {
		try {
			const { client, paypalCheckout } = require('braintree-web');

			const clientToken = await getClientToken();

			const clientInstance = await client.create({
				authorization: clientToken as string,
			});

			const paypalCheckoutInstance = await paypalCheckout.create({
				client: clientInstance,
			});

			await paypalCheckoutInstance.loadPayPalSDK({
				vault: true,
				intent: 'tokenize',
				currency: paymentFormCurrency,
			});

			if (!window.paypal) return;

			createPayPalButton('yearly', paypalCheckoutInstance);
			if (plan === 'monthly' || isMonthlyPricingTest) {
				createPayPalButton('monthly', paypalCheckoutInstance);
			}
			createPayPalButton('lifetime', paypalCheckoutInstance);

			setIsPayPalAvailable(true);
		} catch (err) {
			// Instantiating failed - remove component
			setIsPayPalAvailable(false);
			setIsVisible(false);
		}
	};

	function createPayPalButton(sku: PlanCadence, paypalCheckoutInstance: PayPalCheckout) {
		const getButtonHeight = (): number => {
			if (!document) return 55;

			const { clientWidth: width } = document.body;

			if (width < 769) return 48;
			if (width < 1025) return 52;

			return 55;
		};
		window.paypal
			.Buttons({
				fundingSource: 'paypal', // window.paypal.FUNDING.PAYPAL,
				style: {
					height: getButtonHeight(),
					size: 'responsive',
					shape: 'pill',
				},
				createBillingAgreement: () => {
					onClick();
					return paypalCheckoutInstance.createPayment({
						flow: 'vault' as paypal.FlowType,
						billingAgreementDescription: 'Calm Premium',
						intent: 'tokenize' as paypal.Intent,
						currency: paymentFormCurrency,
						amount: prices.current[sku],
						enableShippingAddress: true,
						shippingAddressEditable: true,
					});
				},
				onApprove: async data => {
					const paymentToken = await paypalCheckoutInstance.tokenizePayment(data);

					return onPaymentAuthorized(paymentToken, sku);
				},
				onCancel: err => onCancel(err, 'paypal'),
				onError,
			})
			.render(`#paypal-button-${sku}`);
	}

	useEffect(
		() => {
			if (shouldShowPayPalCheckout()) {
				initBraintree().catch(() => {
					setIsPayPalAvailable(false);
				});
			}
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	if (!shouldShowPayPalCheckout()) return null;

	return (
		<Wrapper isDisabled={isDisabled}>
			{children}
			{isLoading && <LoadingButton fullWidth isLoading backgroundColor="buttonPaypalYellow" />}
			<ButtonWrapper isHidden={isLoading}>
				<PayPalButtonWrapper activeState={plan} myState="yearly" id="paypal-button-yearly" />
				<PayPalButtonWrapper activeState={plan} myState="monthly" id="paypal-button-monthly" />
				<PayPalButtonWrapper activeState={plan} myState="lifetime" id="paypal-button-lifetime" />
			</ButtonWrapper>
		</Wrapper>
	);
};

export default PayPalButton;
