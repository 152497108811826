import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

const absoluteBackground = css`
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
`;

export const Wrapper = styled.div`
	${absoluteBackground}
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

export const BlackOverlay = styled.div`
	${absoluteBackground}
	background: ${palette('black')};
	content: '';
	opacity: 0.5;
	position: absolute;
`;
