import {
	CreateTokenCardData,
	PaymentMethod,
	StripeCardElement,
	StripeCardNumberElement,
	Token,
} from '@stripe/stripe-js';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { MessageDescriptor } from 'react-intl';

import { CalmTheme } from '@calm-web/design-system';

import { ProductInfo } from '@/hooks/analytics/types';
import { LegacyCalmError } from '@/hooks/api/types';
import { PlanCadence } from '@/store/purchaseParams/types';
import { Subscription } from '@/store/user/types';
import { Service } from '@/utils/getUrl';
import { PromiseWithCalmError } from '@/utils/promiseWithCalmError';
import { JSONObject } from '@/utils/types';

export interface RequestInfo {
	name: string;
	email?: string;
}

export type PaymentType = 'credit_card' | 'google_pay' | 'apple_pay' | 'paypal';
export type SetPaymentTypeAvailable = Dispatch<SetStateAction<boolean | undefined>>;
export type SetCurrentPaymentType = Dispatch<SetStateAction<PaymentType | null>>;

export type CustomerAddress = {
	postalCode?: string;
	region?: string;
	country?: string;
};

export const BEFORE_PURCHASE_STATE = 'before';
export const PURCHASING_STATE = 'purchasing';
export const PURCHASED_STATE = 'purchased';

export type PurchaseState = typeof BEFORE_PURCHASE_STATE | typeof PURCHASING_STATE | typeof PURCHASED_STATE;

export interface OnPurchaseSuccess {
	(subscription: Subscription, selectedPaymentType: PaymentType): Promise<void>;
}

export interface OnPurchaseError {
	(err: LegacyCalmError | string): void;
}

export interface OnPurchaseCancel {
	(err: string | JSONObject, paymentType: PaymentType): void;
}

export interface GenericPaymentArgs {
	requestInfo: RequestInfo;
	cardInfo?: StripeCardElement | StripeCardNumberElement;
	userInfo?: CreateTokenCardData;
	endpoint?: string;
	token?: Token | PaymentMethod | paypal.AuthorizationResponse;
	paymentType?: PaymentType;
	email?: string;
	quantity?: number;
	type?: 'lifetime' | 'annual';
	coupon?: string | null;
	plan?: PlanCadence | 'family_plan_1y_autorenew';
	customerAddress?: CustomerAddress;
	service?: Service;
	dtc_partnership_details?: DTCPartnershipDetails;
}

export interface DTCPartnershipDetails {
	partner_id?: string;
	partner_user_id?: string;
}

export interface OnGenericPaymentSubmitRefresh {
	({
		cardInfo,
		userInfo,
		requestInfo,
		endpoint,
		token,
		paymentType,
		plan,
	}: GenericPaymentArgs): PromiseWithCalmError;
}

export interface PurchaseFormProps {
	children?: ReactNode;
	ctaCopy?: string;
	buttonBgColor?: keyof typeof CalmTheme.palette;
	buttonTextColor?: keyof typeof CalmTheme.palette;
	centerFormElements?: boolean;
	email?: string;
	hideTerms?: boolean;
	hidePriceAfterDisclaimer?: boolean;
	isDisabled?: boolean;
	purchaseTerms?: string | ReactNode;
	onLogout?: () => void;
	onPurchase: OnGenericPaymentSubmitRefresh;
	onSuccess: () => void;
	analyticsPrefix?: string;
	productInfo?: ProductInfo;
	showLoggedInAs?: boolean;
	initialNameValue?: string;
	ignoreSubscribeErrors?: boolean;
	updateUserOnSuccess?: boolean;
	hidePaypal?: boolean;
	hideApplePay?: boolean;
	hideAlternativePaymentMethods?: boolean;
	alignment?: 'center' | 'left' | 'right';
	ignoreSuccessLogging?: boolean;
	isSecondFreeTrial?: boolean;
	formHeading?: string;
	hideBillingAddressFields?: boolean;
	setFormIsValid?: Dispatch<SetStateAction<boolean>>;
	terms?: MessageDescriptor;
	isOfferTerms?: boolean;
	isOrganicTraffic?: boolean;
}
