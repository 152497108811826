import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { CloseButton } from '@calm-web/design-system';

import { useUserState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import ArrowRight from 'icons/right-arrow.svg';

import { BannerConfigType, getActiveConfig } from './data';
import { StyledBanner, BannerMessaging, BannerSpacer, CTAText, BannerArrow, CloseWrapper } from './styles';
import { OfferBannerContextProps } from './types';

export function matchQuery(
	_bannerConfig: BannerConfigType,
	pathname: string,
	query: ParsedUrlQuery,
): boolean {
	if (_bannerConfig.queries) {
		return _bannerConfig.queries.every(({ key, value, path }) => {
			if (path !== pathname) return true;
			return query[key] === value && path === pathname;
		});
	}
	return true;
}

export const OfferBannerContext = createContext<OfferBannerContextProps>({
	isBannerVisible: false,
	bannerComponent: null,
});

export function isWithinDateRange(startDate: string, endDate: string): boolean {
	const now = DateTime.now();
	const start = DateTime.fromFormat(startDate, 'MM/dd/yyyy').startOf('day');
	const end = DateTime.fromFormat(endDate, 'MM/dd/yyyy').endOf('day');
	return now >= start && now <= end;
}

export const BannerComponent: React.FC<{ bannerConfig: BannerConfigType; onClose: () => void }> = ({
	bannerConfig,
	onClose,
}) => {
	const { query } = useRouter();
	const { formatMessage } = useIntl();
	const routerPush = useRouterPush();
	if (!bannerConfig) return null;
	return (
		<div role="region" aria-labelledby="banner-copy">
			<StyledBanner
				bannerConfig={bannerConfig}
				role="link"
				onClick={async e => {
					e.preventDefault();
					await routerPush(bannerConfig.url, {
						...query,
						coupon: bannerConfig.coupon ?? '',
						...(bannerConfig.utm_source && { utm_source: bannerConfig.utm_source ?? '' }),
					});
				}}
				href={bannerConfig.url}
			>
				{/* Empty Div for Spacing */}
				<div></div>
				<BannerMessaging>
					<span id="banner-copy">{formatMessage(bannerConfig.longCopy)}</span>
					<BannerSpacer>|</BannerSpacer>
					<CTAText>{formatMessage(bannerConfig.ctaText)}</CTAText>
					<BannerArrow>
						<ArrowRight height="24px" width="24px" viewBox="0 0 24 18" />
					</BannerArrow>
				</BannerMessaging>
				<CloseWrapper>
					<CloseButton aria-label="close" color="white" onPress={onClose} />
				</CloseWrapper>
			</StyledBanner>
		</div>
	);
};

const OfferBannerProvider = ({ children }: { children?: ReactNode }) => {
	const [isBannerVisible, setIsBannerVisible] = useState(false);
	const [userClosedBanner, setUserClosedBanner] = useState(false);
	const { pathname } = useRouter();
	const bannerConfig = getActiveConfig(pathname);
	const { query } = useRouter();
	const user = useUserState();

	const onClose = useCallback(() => {
		setIsBannerVisible(false);
		setUserClosedBanner(true);
		bannerConfig.onClose();
	}, [bannerConfig]);

	const renderedComponent = useCallback(() => {
		return isBannerVisible ? <BannerComponent bannerConfig={bannerConfig} onClose={onClose} /> : null;
	}, [isBannerVisible, bannerConfig, onClose]);

	useEffect(() => {
		if (
			bannerConfig &&
			bannerConfig.visiblePaths.includes(pathname) &&
			matchQuery(bannerConfig, pathname, query) &&
			isWithinDateRange(bannerConfig.startDate, bannerConfig.endDate) &&
			!userClosedBanner &&
			!user?.subscription?.valid
		) {
			setIsBannerVisible(true);
		} else {
			setIsBannerVisible(false);
		}
	}, [pathname, isBannerVisible, userClosedBanner, bannerConfig, user?.subscription?.valid, query]);

	const value = useMemo(
		() => ({
			isBannerVisible,
			bannerComponent: renderedComponent(),
		}),
		[isBannerVisible, renderedComponent],
	);

	return <OfferBannerContext.Provider value={value}>{children}</OfferBannerContext.Provider>;
};

export default OfferBannerProvider;
