import bowser from 'bowser';
import uuid from 'uuid/v4';

import { EventProps } from '@/hooks/analytics/types';
import { Campaign } from '@/store/campaign/types';
import { ComplianceLevel } from '@/store/complianceLevel/types';
import { Device } from '@/store/device/types';
import { PurchaseParams } from '@/store/purchaseParams/types';
import { Zoom } from '@/store/zoom/types';
import { maybeGetZoomLogEventProps } from '@/utils/b2b/zoom';
import { getCookie } from '@/utils/cookies';
import AnalyticsWhitelist from '@/utils/legacy/analyticsWhitelist';

import slugEventProps, { isSlugEventPropsKey } from './slugEventProps';

interface StripTrailingCharArgs {
	str: string;
	char: string;
}

function stripTrailingChar({ str, char }: StripTrailingCharArgs): string {
	if (str.substr(-1) === char) {
		return str.substr(0, str.length - 1);
	}
	return str;
}

export function getSlug(): string {
	const pathname = window?.location?.pathname ?? '';
	const stripped = stripTrailingChar({
		str: pathname,
		char: '/',
	}).toLowerCase();
	if (stripped.length === 0) return '/';

	// password-reset email passes a token in the url
	// we do not want this value to be sent to any third parties
	// we might be able to delete this once web app is migrated to Auth0
	if (stripped.includes('/password-reset/')) {
		return '/password-reset/REDACTED';
	}

	return stripped;
}

export function getCouponForLogging(coupon?: string | null): string | undefined {
	const queryParamProperties = AnalyticsWhitelist.getPropertiesFromQueryParams(window?.location?.search);
	return coupon ?? (queryParamProperties?.coupon as string);
}

function getSlugSpecificProps(slugKey: string): Record<string, unknown> {
	const slugSpecificEventProps = isSlugEventPropsKey(slugKey) ? slugEventProps[slugKey] : {};
	return slugSpecificEventProps;
}

async function setupEventProps(
	eventProps: EventProps,
	isLoggedIn: boolean,
	coupon: PurchaseParams['coupon'],
	zoom: Zoom | null,
	isMsftTeams: boolean,
	device: Device,
	shareEventProps: Record<string, unknown>,
	complianceLevel: ComplianceLevel,
	hashedUserEmail?: string,
	calmIdentifier?: string,
	campaign?: Campaign | null,
): Promise<EventProps> {
	const browser = bowser.getParser(window?.navigator?.userAgent || '');
	const queryParamProperties = AnalyticsWhitelist.getPropertiesFromQueryParams(window?.location?.search);
	const userHasAdblock = (await import('adblock-detector')).default.detect();

	const slug = getSlug();
	const slugKey = slug.replace(/^\/([^/]*).*$/, '$1');
	const slugSpecificEventProps = getSlugSpecificProps(slugKey);

	const browserInstance = browser.getBrowser();

	const referrer = (() => {
		if (!document?.referrer) {
			return undefined;
		}
		if (document.referrer.indexOf('password-reset') > -1) {
			return '/password-reset'; // Don't log the reset password link
		}
		if (document.referrer.indexOf('gp/') > -1) {
			return '/gp'; // Don't log the guest pass
		}
		if (document.referrer.indexOf('manage-subscription') > -1) {
			return '/manage-subscription'; // Don't log manage subscription
		}
		return document.referrer;
	})();

	const calmDeviceId = getCookie('x-device-id');

	const couponForLogging = getCouponForLogging(coupon);

	return {
		...slugSpecificEventProps,
		...eventProps,
		...queryParamProperties,
		slug,
		...(couponForLogging ? { coupon: couponForLogging } : {}),
		...(referrer ? { referrer } : {}),
		...(browserInstance.name ? { browser: browserInstance.name } : {}),
		...(browserInstance.version ? { browser_version: browserInstance.version } : {}),
		...(calmDeviceId ? { calm_device_id: calmDeviceId } : {}),
		...(hashedUserEmail ? { hashed_user_email: hashedUserEmail } : {}),
		...(calmIdentifier ? { calm_identifier: calmIdentifier } : {}),
		compliance_level: complianceLevel,
		has_adblocker: userHasAdblock,
		is_www_next: true,
		service: 'web-www',
		is_logged_in_user: isLoggedIn,
		event_id: uuid(),
		is_msft_teams: isMsftTeams,
		...maybeGetZoomLogEventProps(device, zoom),
		...shareEventProps,
		...(campaign ?? {}),
	};
}

export default setupEventProps;
