import styled from 'styled-components';
import { palette } from 'styled-tools';

import { maxWidth } from '@calm-web/design-system';

import { COLLAPSED_AUDIO_PLAYER_INDEX } from '@/utils/ui/zIndices';

export const UPSELL_FOOTER_HEIGHT = '92px';

export const UpsellFooterWrapper = styled.a`
	width: 100%;
	height: ${UPSELL_FOOTER_HEIGHT};
	background: ${palette('upsellFooterBackgroundGradient')};
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0;
	z-index: ${COLLAPSED_AUDIO_PLAYER_INDEX + 2};

	${maxWidth('tablet')} {
		display: none;
	}
`;

export const UpsellFooterContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 770px;

	button {
		margin-left: 24px;
	}
`;
