import { JSONObject } from '@segment/analytics-next';
import { PayPalCheckout } from 'braintree-web';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { usePaymentFormValues } from '@/hooks/purchase/usePaymentFormValues';
import { usePricesState, usePurchaseParamsState } from '@/hooks/store';
import { calmLogger } from '@/utils/calmLogger';

import { PayPalToken } from '../PayPalButton/types';
import { ButtonWrapper, LoadingButton, Wrapper } from './styles';
import { createPayPalButton } from './utils';

const PaymentElementsPaypal: React.FC<{
	children?: React.ReactNode;
	isDisabled: boolean;
	isLoading: boolean;
	setSelectedPaymentType: Dispatch<SetStateAction<string | null>>;
	onSubmit: (paypalToken: paypal.AuthorizationResponse) => Promise<void>;
	onError: (errorMessageToDisplay: string, eventProps?: JSONObject, errorCode?: string) => void;
	analyticsPrefix: string;
	clientToken?: string;
}> = ({
	children,
	isDisabled,
	isLoading,
	setSelectedPaymentType,
	onSubmit,
	onError,
	analyticsPrefix,
	clientToken,
}) => {
	const apiRequest = useApi();

	const { plan } = usePurchaseParamsState();
	const prices = usePricesState();
	const { logEvent } = useAnalytics();
	const { paymentFormCurrency } = usePaymentFormValues();

	const [isVisible, setIsVisible] = useState(true);
	const [isMounting, setIsMounting] = useState(true);

	const onApprove = useCallback(
		async (data: PayPalToken, paypalCheckoutInstance: PayPalCheckout) => {
			const paypalToken = await paypalCheckoutInstance.tokenizePayment(data);
			await onSubmit(paypalToken);
		},
		[onSubmit],
	);

	const onCancel = useCallback(
		async (error: { orderId: string }) => {
			logEvent({
				eventName: `${analyticsPrefix} : Cancelled`,
				eventProps: {
					error,
					payment_type: 'paypal',
				},
			});
		},
		[analyticsPrefix, logEvent],
	);

	useEffect(() => {
		const initBraintree = async () => {
			if (!clientToken || !isMounting) return;

			try {
				await createPayPalButton({
					clientToken,
					setSelectedPaymentType,
					paymentFormCurrency,
					amount: prices.current[plan],
					onApprove,
					onCancel,
					onError,
				});

				setIsVisible(true);
				setIsMounting(false);
			} catch (err) {
				// Instantiating failed - remove component
				setIsVisible(false);
			}
		};

		initBraintree().catch(error =>
			calmLogger.error('Error in PaymentElementsPaypal initBraintree', {}, error),
		);
	}, [
		analyticsPrefix,
		apiRequest,
		logEvent,
		onApprove,
		isMounting,
		onCancel,
		onError,
		paymentFormCurrency,
		plan,
		prices,
		setSelectedPaymentType,
		clientToken,
	]);

	if (!isVisible) return null;

	return (
		<Wrapper isDisabled={isDisabled}>
			{children}
			{(isMounting || isLoading) && (
				<LoadingButton fullWidth isLoading backgroundColor="buttonPaypalYellow" />
			)}
			<ButtonWrapper isHidden={isLoading}>
				<div id="paypal-button" />
			</ButtonWrapper>
		</Wrapper>
	);
};

export default PaymentElementsPaypal;
