import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

export interface TransportProps {
	$sizeTheme: 'large' | 'small';
	$isPrimary?: boolean;
}

export const Wrapper = styled.div<TransportProps>`
	align-items: ${p => (p.$sizeTheme === 'large' ? 'auto' : 'center')};
	display: flex;
	flex-direction: ${p => (p.$sizeTheme === 'large' ? 'column' : 'row')};
	justify-content: flex-end;
	color: ${palette('white')};
	height: 100%;
	margin: 0;
	text-align: center;
	width: 100%;

	${minWidth('tablet')} {
		height: auto;
		display: block;
		margin: ${p => (p.$sizeTheme === 'large' ? '24px auto 0' : '0 auto')};
		width: ${p => (p.$sizeTheme === 'large' ? '100%' : '368px')};
	}
`;

export const TransportIcons = styled.div<TransportProps>`
	align-items: center;
	display: ${p => (p.$sizeTheme === 'large' ? 'flex' : 'none')};
	margin: ${p => (p.$sizeTheme === 'large' ? '20px 0' : '0 auto')};
	justify-content: space-between;

	${minWidth('tablet')} {
		display: flex;
	}
`;

export const MobileTransportIcons = styled.div`
	align-items: center;
	display: flex;
	height: 48px;
	justify-content: center;
	margin-left: 4px;
	// -16px to offset the container padding
	margin-right: calc(-16px + 4px);
	width: 48px;

	${minWidth('tablet')} {
		display: none;
	}
`;

export const SeekBarContainer = styled.div<TransportProps>`
	align-items: center;
	display: flex;
	${p => p.$sizeTheme === 'small' && `flex-direction: column;`}

	${minWidth('tablet')} {
		margin-top: 12px;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 0;
	}
`;

const largeTimeStyles = css`
	width: 72px;
`;

const smallTimeStyles = css`
	margin-bottom: 6px;

	&:last-of-type {
		display: none;

		${minWidth('tablet')} {
			display: block;
		}
	}

	${minWidth('tablet')} {
		margin-top: 0;
	}
`;

export const Time = styled.div<TransportProps>`
	font-size: 12px;
	text-align: center;

	${minWidth('tablet')} {
		font-size: 15px;
		text-align: left;
		width: 72px;
	}

	&:last-of-type {
		text-align: right;
	}

	${p => (p.$sizeTheme === 'small' ? smallTimeStyles : largeTimeStyles)}
`;
