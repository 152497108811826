import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FocusRingVariants, FontSizes, FontWeights, Text } from '@calm-web/design-system';

import ApplePayButton from '@/components/purchase/ApplePayButton';
import GooglePayButton from '@/components/purchase/GooglePayButton';
import PayPalButton from '@/components/purchase/PayPalButton';
import { PaymentType } from '@/components/purchase/PurchaseForm/types';
import { useAnalytics } from '@/hooks/analytics';
import { usePartnerState } from '@/hooks/store';

import messages from './messages';
import { CCButton, CCIcon, CircleCheck, Heading, ThirdPartyButtonsWrapper } from './styles';
import { PaymentTypeSelectorProps } from './types';

const PaymentTypeSelector = ({
	currentPaymentType,
	setCurrentPaymentType,
	onPurchaseSuccess,
	onPurchaseError,
	onPurchaseSubmit,
	onPurchaseCancel,
	requestInfo,
	isLoading,
	isDisabled,
	hidePaypal,
	hideApplePay,
	setErrorCopy,
	shouldUsePaymentFormPrice,
	showHeading = true,
	isMonthlyPricingTest = false,
}: PaymentTypeSelectorProps) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const partner = usePartnerState();
	const partnerSpecificAnalytics = {
		partner_id: partner?.id,
		partner_type: [partner?.category?.toLowerCase() || null],
	};

	const [isApplePayAvailable, setIsApplePayAvailable] = useState<boolean>();
	const [isGooglePayAvailable, setIsGooglePayAvailable] = useState<boolean>();
	const [isPayPalAvailable, setIsPayPalAvailable] = useState<boolean>();

	const onPaymentButtonClick = (paymentType: PaymentType) => {
		setErrorCopy(null);
		logEvent({
			eventName: 'Subscribe : Payment Type : Selected',
			eventProps: {
				payment_type: paymentType,
				...(partner ? partnerSpecificAnalytics : {}),
			},
		});
		setCurrentPaymentType(paymentType);
	};

	const numThirdPartyButtons = [isGooglePayAvailable, isApplePayAvailable, isPayPalAvailable].filter(
		_ => _,
	).length;

	const circleCheckFill = 'accessibleBrandGradient';

	return (
		<>
			{showHeading && <Heading>{formatMessage(messages.heading)}</Heading>}
			<ThirdPartyButtonsWrapper $numColumns={numThirdPartyButtons}>
				{!hidePaypal && (
					<PayPalButton
						onClick={() => onPaymentButtonClick('paypal')}
						onSubmit={onPurchaseSubmit}
						onSuccess={onPurchaseSuccess}
						onCancel={onPurchaseCancel}
						onError={onPurchaseError}
						requestInfo={requestInfo}
						isLoading={currentPaymentType === 'paypal' && isLoading}
						isDisabled={isDisabled || isLoading}
						setIsPayPalAvailable={setIsPayPalAvailable}
						isMonthlyPricingTest={isMonthlyPricingTest}
					>
						{currentPaymentType === 'paypal' && <CircleCheck $fill={circleCheckFill} />}
					</PayPalButton>
				)}
				{!hideApplePay && (
					<ApplePayButton
						onClick={() => onPaymentButtonClick('apple_pay')}
						isDisabled={isDisabled || isLoading}
						onSubmit={onPurchaseSubmit}
						onSuccess={onPurchaseSuccess}
						onError={onPurchaseError}
						onCancel={onPurchaseCancel}
						requestInfo={requestInfo}
						isLoading={currentPaymentType === 'apple_pay' && isLoading}
						setIsApplePayAvailable={setIsApplePayAvailable}
					>
						{currentPaymentType === 'apple_pay' && <CircleCheck $fill={circleCheckFill} />}
					</ApplePayButton>
				)}
				<GooglePayButton
					buttonBackgroundColor={'black'}
					onClick={() => onPaymentButtonClick('google_pay')}
					onSubmit={onPurchaseSubmit}
					onSuccess={onPurchaseSuccess}
					onError={onPurchaseError}
					onCancel={onPurchaseCancel}
					isLoading={currentPaymentType === 'google_pay' && isLoading}
					isDisabled={isDisabled || isLoading}
					requestInfo={requestInfo}
					setIsGooglePayAvailable={setIsGooglePayAvailable}
					shouldUsePaymentFormPrice={shouldUsePaymentFormPrice}
				>
					{currentPaymentType === 'google_pay' && <CircleCheck $fill={circleCheckFill} />}
				</GooglePayButton>
			</ThirdPartyButtonsWrapper>
			<Text align="center" el="p" size={FontSizes.base} weight={FontWeights.Medium}>
				{formatMessage(messages.or)}
			</Text>
			<CCButton
				aria-label={formatMessage(messages.ccButtonLabel)}
				fullWidth
				backgroundColor="white"
				textColor="gray7"
				onPress={() => onPaymentButtonClick('credit_card')}
				isDisabled={isDisabled || isLoading}
				focusRingVariant={FocusRingVariants.Dark}
				data-testid="subscribe-cc-button"
				aria-current={currentPaymentType === 'credit_card'}
			>
				{currentPaymentType === 'credit_card' && <CircleCheck />}
				<CCIcon aria-hidden />
				{formatMessage(messages.ccButtonLabel)}
			</CCButton>
		</>
	);
};

export default PaymentTypeSelector;
