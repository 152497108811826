import { ApiAsset } from '@/components/app/cards/PacksList/types';
import { BrowserLanguage } from '@/store/browserLanguage/types';
import { Guide } from '@/store/guide/types';

export interface Author {
	id: string;
	name: string;
	should_display: boolean;
}

export interface Personnel {
	id: string;
	name: string;
	is_composer: boolean;
	created_at: string;
	updated_at: string;
	bio?: string;
	headshot?: ApiAsset;
	medium_bio?: string;
	short_bio?: string;
}

export interface Program {
	author?: Author;
	background_image: ApiAsset;
	titled_background_image?: ApiAsset;
	cell_image: ApiAsset;
	content_group: string;
	content_type: string;
	created_at: string;
	description?: string;
	guides: Guide[];
	host?: Personnel;
	id: string;
	guest?: Personnel;
	language: BrowserLanguage;
	meditation_type: string;
	metadata?: Record<string, string>;
	narrator?: Personnel;
	position: number;
	published_at: string;
	scheduled_program_type?: string;
	should_play_as_guide?: boolean;
	subtitle?: string;
	title: string;
	untitled_cell_image: ApiAsset;
	updated_at: string;
	variant_id: string;
	is_hidden?: boolean;
	search_category?: string;
	session_highlights?: { highlights: string[] };
	upsell_variant: string;
	is_clinical: boolean;
}

export const SET_PROGRAM = 'SET_PROGRAM';

interface SetProgramAction {
	type: typeof SET_PROGRAM;
	payload: Program | null;
}

export type ProgramActionType = SetProgramAction;
